/deep/ .el-dialog{
  background: $theme-hue;
}
.rebateReportDialog {
  > .title {
    margin-bottom: 30px;

    p {
      font-size: 18px;
      color: $white;
      margin-bottom: 10px;
      font-weight: bold;
      font-family: 'Gilroy SemiBold';
      span {
        color: #6bdace;
      }
    }
  }

  .info {
    span {
      font-size: 16px;
      color: #6bdace;
    }

    p {
      font-size: 16px;
      margin-top: 13px;
      margin-bottom: 0;
      color: $white;
      padding-right: 10px;
    }
  }

  .echarts {
    width: 100%;
    height: 180px;
    margin-bottom: 35px;
  }

  .table_content {
    .title {
      p {
        line-height: 1;
        margin-bottom: 10px;

        span {
          font-size: 16px;
          color: $white;
          @include rtl-sass-prop(padding-right, padding-left, 10px);
          font-weight: bold;
        }
      }
    }

    // table表格
    .table_box {
      width: 100%;
      height: 391px;
      margin-bottom: 0;

      .el-table {
        line-height: 51px;
      }
    }
  }
}
@media (max-width: 414px) {
  #rebate_report {
    /deep/ .el-dialog__body {
      @include rtl-sass-value(text-align, left, right);

      > .title {
        p {
          font-size: 13px;
        }
      }

      .info {
        li {
          margin-bottom: 15px;

          p {
            font-size: 13px;
          }
        }
      }

      .table_content {
        .title {
          p {
            span {
              font-size: 13px;
            }

            img {
              width: 10px;
            }
          }
        }
      }
    }
  }
}
@import './reset_el.scss';
