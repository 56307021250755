@import '@/assets/css/components/themeBox.scss';
@import '@/assets/css/components/table.scss';
@import '@/assets/css/components/defaultDatePickerLayout.scss';

#rebate_report {
  .query {
    margin-bottom: 40px;

    .top {
      width: 80%;
      margin: 0 auto;

      /deep/ .accounts_select {
        @include rtl-sass-prop(border-right, border-left, 1px solid $black3 !important);
      }

      @include screen-mobile1() {
        width: 100%;
        display: flex;
        flex-direction: column;
      }
      /deep/ .el-cascader {
        @include rtl-sass-prop(border-left, border-right, 1px solid $black3 !important);
      }
      /deep/.el-select,
      /deep/ .el-cascader {
        flex: 1;
        height: 45px;
        background: $black2;

        .el-icon-arrow-down,
        .el-select__caret {
          color: $white;
          font-size: 20px;
        }

        .el-select__caret:before {
          content: '\e6e1';
        }

        .el-input__inner {
          border: none;
        }

        @include screen-mobile1() {
          width: 100%;
          border: 0 !important;
          border-bottom: 1px solid #545051 !important;
        }
      }

    }

    .select_lbox {

      .ib-button {
        min-width: 110px;
        margin-left: 10px;
      }

      @include screen-mobile1() {
        align-items: center;
        flex-direction: column;

        .ib-button {
          margin-top: 15px;
          margin-left: 0;
        }
      }

      /deep/.update_container {

        @include screen-mobile1() {
          width: 100%;
          border: 0 !important;
          border-bottom: 1px solid #545051 !important;
        }
      }
    }

    .bottom {
      margin-top: 20px;
    }

    // .query_content_main {
    //   display: grid;
    //   grid-template-columns: 14% 55% 14% 12%;
    //   grid-template-areas: 'left_box calendar cascader_box right_box';
    //   column-gap: calc(5% / 3);
    //   margin-bottom: 30px;

    //   .left_box {
    //     grid-area: left_box;
    //   }

    //   .calendar {
    //     grid-area: calendar;
    //   }

    //   .cascader_box {
    //     grid-area: cascader_box;
    //   }

    //   .right_box {
    //     grid-area: right_box;
    //     justify-self: center;
    //   }

    //   .btn_update_small {
    //     /deep/ span {
    //       font-size: 12px !important;
    //     }
    //   }
    // }

    .theme_box {
      display: flex;
      flex-wrap: wrap;

      .box-btn {
        background: $theme-hue;
        height: 187px;
        border: none;
        margin-top: 30px;
        margin-right: 25px;
        width: calc(33.333% - 25px);

        @include screen-mobile() {
          width: 100%;
          justify-self: center;
          margin-right: 0;
        }

        .content {
          padding: 20px;

          p {
            font: normal normal 300 36px/44px Proxima Nova;
          }

          span {
            font: normal normal 300 16px/23px Proxima Nova;
          }
        }
      }
    }
  }

  .content {
    .download_container {
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin: 0;
    }

    .table_box {
      margin: 0px;

      /deep/ .el-table tr {
        background: $theme-hue;
      }
    }
  }
}

@media (max-width: 1024px) {
  #rebate_report .query .query_content_main {
    grid-template-columns: 49% 49%;
    row-gap: 20px;
    margin-bottom: 0;
    grid-template-areas:
      'left_box left_box'
      'calendar calendar'
      'cascader_box right_box';

    .left_box,
    .cascader_box {
      margin: 0 auto;

      >div {
        max-width: 250px;
      }
    }

    .right_box {
      margin: 0 auto;

      button {
        max-width: 250px;
      }
    }
  }
}

@media (max-width: 550px) {
  #rebate_report .query .query_content_main {
    grid-template-columns: 100%;
    grid-template-areas:
      'left_box'
      'calendar'
      'cascader_box'
      'right_box';

    .left_box,
    .cascader_box {
      margin: unset;

      >div {
        width: 100%;
        max-width: unset;
      }
    }
  }
}